import axios from "axios";
import env from "@beam-australia/react-env";

const API_BASE_URL = env('TREE_MAKER_API_BASE_URL');

const treeMakerApi = {
  get(uri, params = {}) {
    return axios.get(API_BASE_URL + uri, {params});
  },
}

export default treeMakerApi
