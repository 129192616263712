import React from 'react';
import './scss/style.scss';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

UIkit.use(Icons)
const loading = (
    <div className="pt-3 text-center">
        loading...
    </div>
)

// Containers
const MainLayout = React.lazy(() => import('./containers/Layout'));

function App() {
    return (
        <Router>
            <React.Suspense fallback={loading}>
                <Switch>
                    <Route path="/" name="Home" render={props => <MainLayout {...props} />} />
                </Switch>
            </React.Suspense>
        </Router>
    )
}

export default App;
