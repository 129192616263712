import {DateTime, Interval} from "luxon";
import hash from "hash-sum";

export const getAnswerText = (question, resultTree) => {
    if (question.escapeChosen) {
        return 'Uw antwoord staat er niet tussen';
    }
    let answer = question.answers
        .filter((answer) => answer.chosen)
        .map((answer) => answer.name)
        .join(', ');

    const [answerPlaceholder] = question.answers
        .filter((answer) => answer.chosen)
        .map((answer) => answer.navPlaceholder);


    let placeholder = question.navPlaceholder;

    if (answerPlaceholder && answer.length && !question.skipped) {
        const answerPlaceholderEval = answerPlaceholder
            .replaceAll(/\\w/g, ' ')
            .replace(/`/g, "\\`")

        answer = replacePlaceholders(answerPlaceholderEval, answer, question, resultTree)
    } else if (placeholder && answer.length && !question.skipped) {
        const placeholderEval = placeholder
            .replaceAll(/\\w/g, ' ')
            /* eslint-disable-next-line */
            .replace(/`/g, "\`")

        answer = replacePlaceholders(placeholderEval, answer, question, resultTree)
    }

    return answer ?? '-';
};

/**
 * Replace placeholder values, see https://regex101.com/r/RYTyMz/1 for an example regex
 * Supported formats: ${answer}, ${answer|daysFromNow}
 *
 * Pipe filters are inspired from https://twig.symfony.com/doc/3.x/filters/index.html
 */
export const replacePlaceholders = (text, answer, question, resultTree) => {
    const regex = /\${(.*?)}/gm;

    if (question && resultTree) {
        text = replaceTagsWithValues(text, question, answer, resultTree)
    }

    return replaceCalculations(text.replace(regex, (match, p1) => {
        // Split string, extract (optional) filters
        const placeholderItems = p1.split('|')

        // We assume that the first item is always 'answer'
        if (placeholderItems.length === 1) {
            return answer
        }

        // Remove first item (the answer)
        placeholderItems.shift()

        // Apply filters
        placeholderItems.forEach((functionName) => {
            try {
                answer = filterFunctions[functionName](answer)
            } catch (error) {
                console.log(error)
            }
        })

        return answer
    }))
}

export const replaceTagsWithValues = (expressionValue, question, answer, resultTree) => {
    const regex = /\[.+?\]/g;

    return expressionValue.replace(regex, (match) => {
        /**
         * Supported tags:
         *
         * [answer.date]
         * [answer.option]
         * [answer.text]
         * [answer.date.daysFromNow]
         * [question.#514c6821.date]
         * [question.#514c6821.option]
         * [question.#514c6821.text]
         * [question.#514c6821.date.daysFromNow]
         */

        const obj = {
            'date': (value) => {
                return `${DateTime.fromFormat(value, 'd-M-yyyy').toSeconds()}`
            },
            'option': (value, answers) => `'${convertIntToCharacter(answers.findIndex(_answer => _answer.chosen))}'`,
            'text': (value = '', answers, format = 'text') => {
                switch (format) {
                    case 'currency':
                        return parseFloat(value.replaceAll(',', '.'))
                    case 'integer':
                        return parseInt(value)
                    case 'text':
                    default:
                        return `'${value}'`
                }
            },
            'date.daysFromNow': (dateAsString) => getDaysFromNow(dateAsString),
        }

        const tag = match.slice(1, -1)
        const targetValue = getTargetValue(tag, question, answer, resultTree)

        // remove first item and put back together
        const property = getTagPropertyPath(tag)

        if (obj.hasOwnProperty(property)) {
            const targetAnswers = property === 'option' ? getTargetAnswers(tag, question, resultTree) : []

            return obj[property](targetValue, targetAnswers, question?.format)
        }

        return match
    })
}

const filterFunctions = {
    daysFromNow: function (dateAsString, format = 'd-M-yyyy') {
        const start = DateTime.fromFormat(dateAsString, format)
        const end = DateTime.now().startOf('days')

        if (start < end) {
            return Interval.fromDateTimes(start, end).length('days')
        }

        return Interval.fromDateTimes(end, start).length('days')
    }
}

function convertIntToCharacter(index) {
    if (index < 0) {
        return index
    }

    return String.fromCodePoint(97 + index).toUpperCase()
}

export function getDaysFromNow(dateAsString, format = 'd-M-yyyy') {
    const start = DateTime.fromFormat(dateAsString, format)
    const end = DateTime.now().startOf('days')

    if (start < end) {
        const interval = Interval.fromDateTimes(start, end)

        return interval.length('days')
    }

    const interval = Interval.fromDateTimes(end, start)

    return interval.length('days')
}

const getTargetValue = (tag, question, answer, resultTree) => {
    let targetEntity = answer
    const tagParts = tag.split('.')
    tagParts.shift()

    if ((tagParts[0] ?? '').startsWith('#')) {
        // Tag is something like [question.#514c6821.text]
        const targetQuestion = getQuestionFromResultTree(tagParts[0], resultTree)

        if (!targetQuestion || !targetQuestion.hasOwnProperty('answers')) {
            return ''
        }

        targetEntity = targetQuestion.answers.find(_answer => _answer.chosen === true)

        return targetEntity?.name ?? ''
    }

    return typeof targetEntity === 'object' ? targetEntity?.name ?? '' : answer
}

const getQuestionFromResultTree = (hashString, resultTree) => {
    const {questions} = resultTree
    const hashToCompare = hashString.slice(1)

    return questions.find(_question => hashToCompare === hash(_question.id))
}

const getTagPropertyPath = (tag) => {
    const parts = tag.split('.').slice(1)

    if ((parts[0] ?? '').startsWith('#')) {
        parts.shift()
    }

    return parts.join('.')
}

const getTargetAnswers = (tag, question, resultTree) => {
    const tagParts = tag.split('.')
    tagParts.shift()

    if (tagParts[0].startsWith('#')) {
        // Tag is something like [question.#514c6821.text]
        const targetQuestion = getQuestionFromResultTree(tagParts[0], resultTree)

        if (!targetQuestion || !targetQuestion.hasOwnProperty('answers')) {
            return []
        }

        return targetQuestion.answers
    }

    return question.answers
}

/**
 * Allow for calculations in the given text, in the format {{...}}
 * Example: {{ ${answer} / 2 }} or {{ 8 - 2 + 3 }}
 *
 * @param text
 * @returns string
 */
const replaceCalculations = (text) => {
    const regex = /{{(.*?)}}/gm;

    return text.replace(regex, (match, p1) => {
        // Replace Markdown escape characters
        const functionAsString = p1.replaceAll('\\*', '*')

        try {
            const f1 = createFunction(functionAsString)

            return f1()
        } catch (error) {
            console.log('Error parsing function')
            console.log('function:', functionAsString)
            return match
        }
    })
}

function createFunction(parsedFormula) {
    /* eslint-disable-next-line */
    return new Function(`return ${parsedFormula};`)
}

const obtainOriginalFileName =  (updatedContentUrl) => {
    const contentUrl = updatedContentUrl.split('/').pop();
    let contentUrlParts = contentUrl.split('_');
    contentUrlParts.shift();
    return contentUrlParts.join('_');
};
export const getOriginalFileName = obtainOriginalFileName;

export const getUploadedFileList = (question) => {
    return question.mediaObjects.map((mediaObject) => obtainOriginalFileName(mediaObject.contentUrl)).join(', ');
};
