import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {treeEntity} from "../../schemas";
import treeMakerApi from "../../services/treeMakerApi";
import {initialiseApp} from '../result/resultSlice'

// Use the uri as ID
const treesAdapter = createEntityAdapter()

export const getTree = createAsyncThunk("trees/getTree", async ({id, isPreview = false}) => {
    if (isPreview) {
        const results = await treeMakerApi.get(`/api/public/tree_publications_preview/${id}`);
        const normalized = normalize(results.data, treeEntity);
        return normalized.entities;
    }

    const uri = `/api/trees/${id}`
    const results = await treeMakerApi.get(`/api/public/tree_publications?published=true&tree=${uri}`);
    const normalized = normalize(results.data['hydra:member'], [treeEntity]);
    return normalized.entities;
})

export const getTreeByTreePath = createAsyncThunk("trees/getTreeByTreePath", async (path, {rejectWithValue}) => {
        try {
            const results = await treeMakerApi.get(`/api/public/tree_publications?published=true&tree=${path}`);
            const normalized = normalize(results.data['hydra:member'], [treeEntity]);
            return normalized.entities;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const treesSlice = createSlice({
    name: 'trees',
    initialState: treesAdapter.getInitialState({
        currentTreeId: null,
        preview: false,
        ids: [],
        entities: {},
    }),
    reducers: {
        changeCurrentTreeId(state, {payload: treeId}) {
            state.currentTreeId = treeId
        },
        setPreview(state, {payload: preview}) {
            state.preview = preview
        }
    },
    extraReducers: {
        [getTree.fulfilled]: (state, action) => {
            treesAdapter.upsertMany(state, action.payload.trees)
        },
        [getTreeByTreePath.fulfilled]: (state, action) => {
            treesAdapter.upsertMany(state, action.payload.trees || [])
        },
        [initialiseApp.fulfilled]: (state, {payload}) => {
            treesAdapter.upsertMany(state, payload?.trees ?? [])
            const trees = Object.values(payload.trees)
            const treePublication = trees[0]
            state.currentTreeId = treePublication.id
        },
    },
});

export const {changeCurrentTreeId, setPreview} = treesSlice.actions;
export default treesSlice.reducer
// Rename the exports for readability in component usage
export const {
    selectById: selectTreeById,
} = treesAdapter.getSelectors(state => state.trees)
