import {createSlice} from "@reduxjs/toolkit";

/**
 * 0 = no log
 * 1 = just event
 * 2 = event and ready state
 * 3 = 1 & 2 plus payload data
 */
const logLevel = 0;

const socketInitialState = {
    currentCode: null,
    connection: {
        status: {
            reconnect: true,
            connected: false,
            outOfSync: false,
            beginReconnect: 0,
            reconnectAttempt: 0,
            reconnected: 0,
            broken: 0,
            closed: 0,
            error: 0,
            message: 0,
            open: 0,
            connect: 0,
            disconnect: 0,
            send: 0,
        }
    },
};

const socketSlice = createSlice({
    name: 'socket',
    initialState: socketInitialState,
    reducers: {
        updateDisconnected: (state) => {
            state.connection.status.reconnect = false;
        },
        onUpdateSocketStatusParams: (state, {payload}) => {
            Object.assign(state.connection.status, payload);
        },
    },
    extraReducers: {
        'GI_LAW_WEB_SOCKET_PREFIX::CONNECT': (state, {payload}) => {
            state.connection.status.connect++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::CONNECT'];

            if (logLevel > 2) {
                consoleParams.push(state, payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }

        },
        'GI_LAW_WEB_SOCKET_PREFIX::BEGIN_RECONNECT': (state, {payload}) => {
            state.connection.status.beginReconnect++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::BEGIN_RECONNECT'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::RECONNECT_ATTEMPT': (state, {payload}) => {
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::RECONNECT_ATTEMPT'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            state.connection.status.reconnectAttempt++;
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::RECONNECTED': (state, {payload}) => {
            state.connection.status.reconnected++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::RECONNECTED'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::BROKEN': (state, {payload}) => {
            state.connection.status.broken++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::BROKEN'];
            if (logLevel > 1) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::CLOSED': (state, {payload}) => {
            state.connection.status.closed++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::CLOSED'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::ERROR': (state, {payload, meta}) => {
            state.connection.status.error++;
            if (meta.message && meta.message.includes('Dispatch WEBSOCKET_CONNECT first')) {
                state.connection.status.reconnect = true;
            }
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::ERROR'];
            if (logLevel > 1) {
                consoleParams.push(payload.message, "\n", meta.message);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::MESSAGE': (state, {payload}) => {
            state.connection.status.message++;
            state.connection.status.reconnect = false;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::MESSAGE socket'];
            if (logLevel > 2) {
                consoleParams.push(JSON.parse(payload.message), 'state,');
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::OPEN': (state, {payload}) => {
            state.connection.status.closed++;
            state.connection.status.outOfSync = true;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::OPEN'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::DISCONNECT': (state, {payload}) => {

            state.connection.status.disconnect++;
            console.log('GI_LAW_WEB_SOCKET_PREFIX::DISCONNECT');
        },
        'GI_LAW_WEB_SOCKET_PREFIX::SEND': (state, {payload}) => {
            state.connection.status.send++;
            const consoleParams = ['GI_LAW_WEB_SOCKET_PREFIX::SEND'];
            if (logLevel > 2) {
                consoleParams.push(payload);
            }
            if (logLevel > 0) {
                console.log(...consoleParams);
            }
        },
        'result.moveToNextQuestion': (state) => {
            state.connection.status.outOfSync = true;
        }
    }
});
export const {
    updateDisconnected: resetDisconnected,
    onUpdateSocketStatusParams: updateSocketStatusParams,
} = socketSlice.actions;
export default socketSlice.reducer;
