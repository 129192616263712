export const TREE = Object.freeze({
    STATUS: {
        COMPLETE: 'request_fulfilled',
        REFRESH: 'replace_tree_nodes_only',
        INSERT: 'process_new_tree',
        ROUTING_ERROR: 'tree_routing_error',
        UNPUBLISHED_TREE_ERROR: 'unpublished_tree_error',
        MISSING_NEXT_TREE_ERROR: 'missing_next_tree_error',
        MISSING_NEXT_TREE_SKIP_ERROR: 'missing_next_tree_skip_error',
        MISSING_NEXT_TREE_SUB_TREE_ERROR: 'missing_next_tree_sub_tree_error',
        MISSING_CATEGORY_QUESTION_ERROR: 'missing_category_question_error',
        MISSING_CHOICE_QUESTION_SUB_TREE_ERROR: 'missing_choice_question_sub_tree_error',
        FINISHED: 'finished',
        EXIT: 'early_exit',
        IS_LOOP: 'is_loop',
        CHOOSE_FLOW_COMPLEXITY: 'choose_flow_complexity',
        TERMINATED: ['tree_routing_error', 'unpublished_tree_error', 'finished', 'early_exit', 'missing_next_tree_error']
    },
});

export const NODE = Object.freeze({
    TYPE: {
        QUESTION_NODE: 'QuestionNode',
        SUB_TREE_NODE: 'SubTreeNode',
        TEXT_NODE: 'TextNode',
        CHOOSE_COMPLEXITY: ' ChooseComplexity'
    }
});

export const QUESTION_NODE = Object.freeze({
    TYPE: {
        CHOICE: 'ChoiceQuestion',
        TEXT: 'TextQuestion',
        DATE: 'DateQuestion',
        FILE_UPLOAD: 'FileUploadQuestion',
        YES_NO: 'YesNoQuestion',
        SLIDER: 'SliderQuestion',
        SMILEY: 'SmileyQuestion',
        OPTIONAL_ROUTING: [
            'ChoiceQuestion', 'YesNoQuestion',
        ],
        SINGLE_ANSWER: ['TextQuestion', 'SliderQuestion', 'SmileyQuestion']
    },
    VALUES: {
        YES_NO: [
            'Ja', 'Nee', 'Weet ik niet'
        ],
        SMILEYS: [
            'heel ontevreden', 'ontevreden', 'neutraal', 'tevreden', 'heel tevreden'
        ],
        SLIDERS: {
            "ONE": ['Een beetje', 'Matig', 'Gemiddeld', 'Erg', 'Heel erg'],
            "TWO": ['Geen', 'Een beetje', 'Gemiddeld', 'Erg', 'Heel erg'],
            "THREE": ['Geen', 'Weinig', 'Gemiddeld', 'Veel', 'Heel veel'],
        },
        DEFAULT: {
            DATE: 'Ik wil deze vraag overslaan',
            TEXT: 'Ik wil deze vraag overslaan',
            DEFAULT_SKIP: 'Ik wil deze vraag overslaan',
            UNSURE: 'Ik weet het niet'
        }
    }
});

export const ROUTING = Object.freeze({
    TYPE: {
        BASIC: 'basic',
        QUESTION_ANSWER: 'question_answer',
        SUB_TREE: 'sub_tree',
        END: 'exit',
        CHANGING: ['question_answer', 'sub_tree']
    },
    OPTIONAL: {
        YES_NO: 'yes-no-unsure',
        WITH_ICONS: 'with-icons',
        WITHOUT_ICONS: 'without-icons'
    },
    LINK: {
        HOMEPAGE: 'https://www.rechtsbijstandportaal.nl/',
        PRODUCTION: 'https://flow.rechtsbijstandportaal.nl/start-flow-route?q_06f026c5=question&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4',
    }
});

export const CONTENT = Object.freeze({
    OTHER_CHOICE: {
        PLACEHOLDER: 'Anders, namelijk...',
        ANSWER_TEXT: 'Anders, namelijk:',
        ICON: '/icons/Idee.svg'
    }
});

export const META = Object.freeze({
    NAV_PLACEHOLDER: '4', // samenvatting
    SKIP_SUMMARY: 'overslaan_samenvatting',
    EARLY_EXIT: 'early_access',
    EARLY_EXIT_DESCRIPTION: 'early_access_description',
    EARLY_EXIT_DEFAULT: {
        exit: false,
        description: ''
    },
    QUESTION_EXPLANATION: 'sub_header',
    ANSWER_INFO: 'sub_header',
    SUBJECT_TARGET: 'question_with_subject',
    SPECIALISM_DEPARTMENT: 'specialism',
    RHV_RATING: 'blue_arrow_rating',
    RHV_RATING_CATEGORY: 'blue_arrow_category',
    RHV_RATING_TEXT: 'blue_arrow_text'
});

export const SAVE = Object.freeze({
    STATE: {
        INTERIM: 'save_case_before_end_of_flow',
        EXISTING_CLIENT_INTERIM: 'existing_client_save_case_before_end_of_flow',
        END_FLOW: 'save_case_end_of_flow',
        EXISTING_CLIENT_END_FLOW: 'existing_client_save_case_end_of_flow',
        RHV_CLIENT_END_FLOW: 'rhv_client_save_case_end_of_flow',
        ANONYMOUS: 'save_case_without_email',
        EXPIRED: 'email_has_expired',
        FROM_ADMIN: 'return_link_from_admin_portal',
        AFTER_RESTORE: 'case_restored_after_user_takes_break',
        INTERIM_OR_EXPIRED: ['save_case_before_end_of_flow', 'email_has_expired'],
        ADMIN_OR_RESTORE: ['return_link_from_admin_portal', 'case_restored_after_user_takes_break'],
        NO_CANCEL_BUTTON: ['email_has_expired', 'existing_client_save_case_end_of_flow'],
        INTERIM_STATES: ['existing_client_save_case_before_end_of_flow', 'save_case_before_end_of_flow'],
        FOLLOW_MAGIC_LINK_STATES: ['save_case_without_email', 'existing_client_save_case_end_of_flow'],
        END_STATES: ['save_case_end_of_flow', 'existing_client_save_case_end_of_flow'],
    },
    DIALOG: {
        INTERIM: {
            title: 'Pauze nemen?',
            state: 'save_case_before_end_of_flow',
            buttons: {
                save: 'Opslaan',
                cancel: 'Annuleren'
            },
        },
        EXISTING_CLIENT_INTERIM: {
            title: 'Opslaan',
            state: 'existing_client_save_case_before_end_of_flow',
            buttons: {
                save: 'Opslaan',
                cancel: 'Annuleren'
            },
        },
        END_FLOW: {
            title: 'Gegevens invoeren',
            state: 'save_case_end_of_flow',
            buttons: {
                save: 'Verstuur',
                cancel: 'Overslaan'
            },
        },
        EXISTING_CLIENT_END_FLOW: {
            title: 'Naar dossier',
            state: 'existing_client_save_case_end_of_flow',
            buttons: {
                save: 'Naar dossier',
                cancel: 'none'
            },
        },
        EXPIRED: {
            title: 'Oeps, uw link werkt niet meer',
            state: 'email_has_expired',
            buttons: {
                save: 'Verstuur',
                cancel: 'Overslaan'
            },
        },
        ANONYMOUS: {
            title: 'Doorgaan zonder op te slaan?',
            state: 'save_case_without_email',
            buttons: {
                save: 'Bekijk resultaten',
                cancel: 'Terug'
            },
        }
    }
});

export const FILE = Object.freeze({
    TYPES: [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'text/csv',
        'application/csv',
        'text/html',
        'application/rtf',
        'text/rtf',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    ],
    ERROR: {
        CODES: {
            "file-invalid-type": "Dit bestandstype wordt niet ondersteund",
            "file-too-large": "Document te groot, maximaal 5 mb",
            "file-too-small": "Het bestand is te klein",
            "too-many-files": "Te veel besanden",
        }
    }
});

export const SCREEN_SHARE = Object.freeze({
    STATUS: {
        REQUESTED_BY_CLIENT: 'status_requested_by_client',
        STARTED_BY_CLIENT: 'status_started_by_client',
        CLOSED_BY_CLIENT: 'status_closed_by_client',
        ADMIN_STOPPED_SHARING: 'status_admin_stopped_sharing',
        ADMIN_APPROVED_SHARING: 'status_admin_approved_sharing',
        ADMIN_STARTED_SHARING: 'status_admin_started_sharing',
        ADMIN_CANCELLED_SHARE_DIALOG: 'status_admin_cancelled_share_dialog',
        CONNECTED_STATES: ['status_admin_approved_sharing', 'status_started_by_client', 'status_admin_started_sharing'],
        ADMIN_CAN_START_STATES: ['status_admin_stopped_sharing', 'status_started_by_client'],
        ADMIN_DONT_ACCEPT_STATES: ['status_admin_stopped_sharing']
    }
});

export const CLIENT = Object.freeze({
    STATUS: {
        NEW: 'status_new_client',
        REGISTERED: 'status_registered_client',
        PRE_REGISTERED: 'status_pre_registered_client',
        UNREGISTERED: 'status_unregistered_client' // finished flow as unregistered user

    }
});

export const ROLES = Object.freeze({
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    ASSIGNEE_ADMIN: 'ROLE_ASSIGNEE_ADMIN',
    ASSIGNEE_WORKER: 'ROLE_ASSIGNEE_WORKER',
    DEFAULT: 'ROLE_VIRTUAL_USER'
});
