import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import treeReducer from '../features/trees/treesSlice';
import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist'
import resultReducer from '../features/result/resultSlice';
import clientReducer from '../features/client/clientSlice';
import socketReducer from '../features/socket/socketSlice'
import reduxWebsocket from '@giantmachines/redux-websocket';

const prefix = 'GI_LAW_WEB_SOCKET_PREFIX';

const reduxWebsocketMiddleware = reduxWebsocket({
    prefix
});

const middleware = [...getDefaultMiddleware({
    serializableCheck: false
}), reduxWebsocketMiddleware];

const reducers = combineReducers({
    trees: treeReducer,
    result: resultReducer,
    client: clientReducer,
    socket: socketReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['socket']
};

const persistedReducer = persistReducer(persistConfig, reducers);
const rootReducer = (state, action) => {
    if (action.type === 'client/logOut') { // check for action type
        state = undefined;
        storage.removeItem('persist:root');
    }
    return persistedReducer(state, action);
};
export default configureStore({
    reducer: rootReducer,
    middleware,
});
