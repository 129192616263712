import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import env from "@beam-australia/react-env";
import {CONTENT, META, NODE, QUESTION_NODE, ROUTING, TREE} from '../../app/constants'
import treeMakerApi from "../../services/treeMakerApi";
import {normalize} from "normalizr";
import {treeEntity} from "../../schemas";
import {getUploadedFileList} from "../../services/questionHelper";

const resultAdapter = createEntityAdapter();

const imageFolder = env('IMAGE_SERVER_IMAGE_FOLDER_URL');

let entryId = env('TREE_MAKER_ENTRY_POINT_ID');

export const initialiseApp = createAsyncThunk("result/initialiseApp", async ({newEntryId = null}) => {
    // Fetch the latest publication of the given tree
    const resolvedEntryId = newEntryId ?? entryId;
    const uri = `/api/trees/${resolvedEntryId}`;
    const results = await treeMakerApi.get(`/api/public/tree_publications?published=true&tree=${uri}`);
    const normalized = normalize(results.data['hydra:member'], [treeEntity]);

    return normalized.entities;
})

const resultInitialState = {
    resultTree: {
        questions: [],
        currentQuestion: null,
        hasNext: false,
        hasPrevious: false,
        subject: '-',
        category: '-',
        categoryQuestion: '-',
        processTreeStatus: TREE.STATUS.INSERT,
        errorMessage: [],
        loopErrors: [],
        errorLink: null,
        idCurrentTree: null,
        start: true,
        flowType: null,
        firstUnansweredIndex: 0,
        earlyExitDescription: null,
        returnRoutes: [],
        browserHash: null,
        tree: `/api/trees/${entryId}`,
        status: {
            finished: false,
            completed: false,
            restored: false,
            mail_dialog_open: false,
            mail_expired: false,
            error: false,
            newCase: false,
        },
        loading: {
            newDossierLoading: false,
            newClientLoading:false,
            saveDialogStateCase: null,
            saveDialogStateClient: null,
            moreCallsLoading: false
        },
        loadedTrees: [],
    },
    id: null,
    treeNodes: [],
    treeNodeCount: 0,
    tree: `/api/trees/${entryId}`,
    treePublication: undefined,
    screen: {
        role: {
            admin: false,
            client: false,
        },
        canSend: false,
        disconnectAfter: false,
        shareScreenAdminName: '',
        adminName: '',
        screenShareCode: null,
        screenShareOutOfSync: true,
        disconnecting: false,
    },
    mailStatus: {},
};

const extractFromMetadata = (metadata, key) => {
    if (!metadata) {
        return null;
    }
    let [metaValue] = metadata.filter((meta) => meta.metaKey === key).map((meta) => meta.metaValue);
    if (undefined !== metaValue) {
        const empty = '' === metaValue.replace(/[\s\u200B\u2028]/g, '');
        metaValue = empty ? undefined : metaValue;
    }
    return metaValue;
};

/**
 * on the question
 * @param rawQuestion
 */
const getSkipAnswerText = (rawQuestion) => {
    let configuredSkipAnswer = extractFromMetadata(rawQuestion.metadata, META.SKIP_SUMMARY);
    if (typeof configuredSkipAnswer !== 'string') {
        switch (rawQuestion['@type']) {
            case QUESTION_NODE.TYPE.DATE:
                configuredSkipAnswer = QUESTION_NODE.VALUES.DEFAULT.DATE;
                break;
            case QUESTION_NODE.TYPE.TEXT:
                configuredSkipAnswer = QUESTION_NODE.VALUES.DEFAULT.TEXT;
                break;
            default:
                configuredSkipAnswer = QUESTION_NODE.VALUES.DEFAULT.DEFAULT_SKIP;
        }
    }
    return configuredSkipAnswer;
};

const sidenavPlaceholder = (rawQuestion) => {
    return extractFromMetadata(rawQuestion.metadata, META.NAV_PLACEHOLDER);
};

const getAnswerByQuestionType = (type, rawQuestion, chosenTreeNode, id, treeNodes, currentTreeId) => {
    let nextTreeNode = chosenTreeNode.nextTreeNode;
    let returnTreeNode = null;
    let treeNodeReference = null;
    let [treeNodeReferenceOnSkip, exitOnSkip, skipReturnTreeNode] = getSkipChoiceNextTreeReference(treeNodes, rawQuestion, currentTreeId, chosenTreeNode.routingType);
    let routingType = chosenTreeNode.routingType;
    let targetTreeNode = null;
    if (ROUTING.TYPE.SUB_TREE === routingType) {
        targetTreeNode = treeNodes.find((treeNode) => {
            return treeNode['@id'] === nextTreeNode;
        });
        if (targetTreeNode) {
            treeNodeReference = targetTreeNode.expandedNode.tree;
            if (targetTreeNode.nextTreeNode) {
                let targetNextTreeNode = targetTreeNode.nextTreeNode;
                returnTreeNode = {
                    nextTreeNode: targetNextTreeNode,
                    currentTreeId,
                    finishAfter: ROUTING.TYPE.END === targetTreeNode.routingType,
                    escape: false
                };
            }
        }
    }
    const skipAnswerText = getSkipAnswerText(rawQuestion);
    const globalValues = {
        id,
        routingType,
        skipAnswerText,
        nextTreeNode,
        exitOnSkip,
        treeNodeReference,
        treeNodeReferenceOnSkip,
        returnTreeNode,
        targetTreeNode,
        skipReturnTreeNode,
    };
    switch (type) {
        case QUESTION_NODE.TYPE.TEXT:
            return {
                name: '',
                textArea: rawQuestion.textArea,
                maxLength: rawQuestion.maxLength,
                chosen: false,
                placeholder: 'Typ hier uw antwoord',
                questionId: rawQuestion.id,
                multiple: false,
                navPlaceholder: null,
                earlyExit: META.EARLY_EXIT_DEFAULT,
                ...globalValues,
            };
        case QUESTION_NODE.TYPE.SMILEY:
        /* falls through */
        case QUESTION_NODE.TYPE.DATE:
        case QUESTION_NODE.TYPE.SLIDER:
        case QUESTION_NODE.TYPE.YES_NO:
            return {
                name: '',
                chosen: false,
                questionId: rawQuestion.id,
                multiple: false,
                navPlaceholder: null,
                earlyExit: META.EARLY_EXIT_DEFAULT,
                ...globalValues,
            };
        case  QUESTION_NODE.TYPE.FILE_UPLOAD:
            return {
                name: '',
                chosen: false,
                checked: false,
                questionId: rawQuestion.id,
                multiple: false,
                navPlaceholder: null,
                earlyExit: META.EARLY_EXIT_DEFAULT,
                ...globalValues,
            };
        case QUESTION_NODE.TYPE.CHOICE:
            return {
                name: '',
                textArea: true,
                maxLength: 1000,
                chosen: false,
                other: true,
                placeholder: CONTENT.OTHER_CHOICE.PLACEHOLDER,
                questionId: rawQuestion.id,
                answerType: determineAnswerType(rawQuestion),
                navPlaceholder: null,
                styleClass: 'available',
                multiple: false,
                ...globalValues,
            };
        default:
            console.error(`undefined type ${type}`);
    }
};

const determineAnswerType = (rawQuestion) => {
    if (QUESTION_NODE.TYPE.YES_NO === rawQuestion['@type']) {
        return ROUTING.OPTIONAL.YES_NO;
    }
    const withIcons = rawQuestion.choices.every((choice) => {
        if (choice.other) {
            return true;
        }
        if (!choice.metadata) {
            return false;
        }
        return choice.metadata.some((metadata) => metadata.metaKey === 'icon');
    });
    return withIcons ? ROUTING.OPTIONAL.WITH_ICONS : ROUTING.OPTIONAL.WITHOUT_ICONS;
};

/**
 * @todo return null if not question with icon
 *
 * @param answerType
 * @param choice
 * @returns {string}
 */
const getAnswerIconLocation = (answerType, choice) => {
    if (ROUTING.OPTIONAL.WITH_ICONS !== answerType) {
        return '';
    }
    const defaultIcon = choice.other ? CONTENT.OTHER_CHOICE.ICON : '/icons/house.svg';
    const foundIcon = getIconLocation(choice.metadata);
    return foundIcon ? foundIcon : defaultIcon;
};

const checkEarlyExit = (choice) => {
    if (!choice || !choice.metadata) {
        return META.EARLY_EXIT_DEFAULT;
    }
    const exit = 'true' === extractFromMetadata(choice.metadata, META.EARLY_EXIT);
    const description = extractFromMetadata(choice.metadata, META.EARLY_EXIT_DESCRIPTION);
    return {exit, description};
};

const getAnswerNavPlaceholder = (multiple, choice) => {
    if (multiple) {
        return null;
    }
    return extractFromMetadata(choice.metadata, META.NAV_PLACEHOLDER);
};

const getIconLocation = (metadata) => {
    if (!metadata) {
        return null;
    }
    const [contentUrl] = metadata
        .filter((meta) => meta.metaKey === 'icon')
        .map((meta) => meta.metaObject.contentUrl);
    return contentUrl ? `${imageFolder}${contentUrl}` : null;
};

const getAnswerExtraInformation = (choice) => {
    return extractFromMetadata(choice.metadata, META.ANSWER_INFO);
};

const formatAnswersForQuestion = (state, rawQuestion, chosenTreeNode, currentTreeId) => {
    let nextTreeNode = chosenTreeNode.nextTreeNode;
    const {treeNodes} = state;
    let routingType = chosenTreeNode.routingType;
    const currentQuestionRoutingType = chosenTreeNode.routingType;
    let subTreeNode;
    if (routingType === ROUTING.TYPE.SUB_TREE) {
        [subTreeNode] = state.treeNodes.filter((treeNode) => treeNode['@id'] === nextTreeNode);
    }
    let answers = [];
    switch (rawQuestion['@type']) {
        case QUESTION_NODE.TYPE.TEXT:
        /* falls through */
        case QUESTION_NODE.TYPE.DATE:
        case QUESTION_NODE.TYPE.FILE_UPLOAD:
        case QUESTION_NODE.TYPE.SMILEY:
        case QUESTION_NODE.TYPE.SLIDER:
            const singleAnswer = getAnswerByQuestionType(rawQuestion['@type'], rawQuestion, chosenTreeNode, rawQuestion.id, treeNodes, currentTreeId);

            answers = [singleAnswer];
            break;
        case QUESTION_NODE.TYPE.YES_NO:
        /* falls through */
        case QUESTION_NODE.TYPE.CHOICE:
            const answerType = determineAnswerType(rawQuestion);
            rawQuestion.choices.forEach(choice => {
                let hasError = false;
                let choiceRouting = null;
                let targetTreeNode = null;
                let treeNodeReference = null;
                let returnTreeNode = null;
                let answerRoutingType = routingType;
                if (routingType === ROUTING.TYPE.QUESTION_ANSWER) {
                    [choiceRouting] = choice.choiceRoutings;
                    if (choiceRouting) {
                        if (choiceRouting.exitNode) {
                            answerRoutingType = ROUTING.TYPE.END
                        } else {
                            targetTreeNode = state.treeNodes.find((treeNode) => {
                                return treeNode['@id'] === choiceRouting.routeToTreeNode;
                            });
                            if (targetTreeNode) {
                                treeNodeReference = targetTreeNode.expandedNode.tree;
                                nextTreeNode = choiceRouting.routeToTreeNode;
                                const [nextNode] = treeNodes.filter((treeNode) => treeNode['@id'] === targetTreeNode.nextTreeNode);
                                if (!nextNode && NODE.TYPE.SUB_TREE_NODE === targetTreeNode.expandedNode['@type'] && ROUTING.TYPE.END !== targetTreeNode.routingType) {
                                    hasError = true;
                                }
                                // ChoiceQuestion can't have sub tree as routing type for choice
                                if (targetTreeNode.nextTreeNode && NODE.TYPE.SUB_TREE_NODE === targetTreeNode.expandedNode['@type'] && ROUTING.TYPE.END !== targetTreeNode.routingType) {
                                    returnTreeNode = {
                                        nextTreeNode: targetTreeNode.nextTreeNode,
                                        currentTreeId,
                                        finishAfter: ROUTING.TYPE.END === currentQuestionRoutingType,
                                        hasError,
                                        escape: false
                                    };
                                }
                            }
                        }
                    }
                } else if (subTreeNode && subTreeNode.expandedNode) {
                    treeNodeReference = subTreeNode.expandedNode.tree;
                    const targetTreeNode = treeNodes.find((treeNode) => {
                        return treeNode['@id'] === nextTreeNode;
                    });
                    if (targetTreeNode && NODE.TYPE.SUB_TREE_NODE === targetTreeNode.expandedNode['@type'] && ROUTING.TYPE.END !== targetTreeNode.routingType) {
                        returnTreeNode = {
                            nextTreeNode: targetTreeNode.nextTreeNode,
                            currentTreeId,
                            finishAfter: ROUTING.TYPE.END === currentQuestionRoutingType,
                            escape: false
                        };
                    }
                }
                let answer;
                if (choice.other) {
                    answer = getAnswerByQuestionType(QUESTION_NODE.TYPE.CHOICE, rawQuestion, chosenTreeNode, choice.id, treeNodes, currentTreeId);
                    answer.choiceRouting = choiceRouting;
                    answer.nextTreeNode = nextTreeNode;
                    answer.treeNodeReference = treeNodeReference;
                    answer.routingType = answerRoutingType;
                    answer.answerType = answerType;
                    answer.navPlaceholder = getAnswerNavPlaceholder(rawQuestion.multiple, choice);
                    answer.earlyExit = checkEarlyExit(choice);
                    answer.icon = getAnswerIconLocation(answerType, choice);
                    answer.multiple = rawQuestion.multiple;
                    answer.returnTreeNode = returnTreeNode;
                    answer.hasError = hasError;
                } else {
                    answer = {
                        name: choice.name,
                        chosen: false,
                        styleClass: 'available',
                        id: choice.id,
                        answerType,
                        other: choice.other,
                        choiceRouting,
                        multiple: rawQuestion.multiple,
                        icon: getAnswerIconLocation(answerType, choice),
                        navPlaceholder: getAnswerNavPlaceholder(rawQuestion.multiple, choice),
                        earlyExit: checkEarlyExit(choice),
                        information: getAnswerExtraInformation(choice),
                        questionId: rawQuestion.id,
                        treeNodeReference,
                        returnTreeNode,
                        routingType: answerRoutingType,
                        nextTreeNode,
                        targetTreeNode,
                        hasError
                    };
                }
                answer.specialism = extractFromMetadata(choice.metadata, META.SPECIALISM_DEPARTMENT) ?? '-';
                const rating = extractFromMetadata(choice.metadata, META.RHV_RATING) ;
                const category = extractFromMetadata(choice.metadata, META.RHV_RATING_CATEGORY) ;
                const notation = extractFromMetadata(choice.metadata, META.RHV_RATING_TEXT) ;
                answer.rhvPreference = rating && category? {rating, category, notation} : null;
                answers.push(answer);
            });
            break;
        default:
            console.error(`question type ${rawQuestion['@type']} not handled`);
    }
    return {answers, nextTreeNode};
};

const getSubHeader = (rawQuestion) => {
    return extractFromMetadata(rawQuestion.metadata, META.QUESTION_EXPLANATION)
};

const getCheckSkipRoute = (resultTree, treeNodes) => {
    const currentQuestion = resultTree.currentQuestion;
    if ((!currentQuestion.skipped && !currentQuestion.wasSkipped) || !currentQuestion.skipChoice) {
        return null;
    }
    currentQuestion.wasSkipped = currentQuestion.skipped;
    resultTree.questions.forEach((question) => {
        if (question.id === currentQuestion.id) {
            question.wasSkipped = currentQuestion.wasSkipped;
        }
    });
    if (!currentQuestion.skipped) {
        return null;
    }
    return currentQuestion.skipped ? currentQuestion.skipChoice.routeToTreeNode : null;
};

const getSkipChoiceNextTreeReference = (treeNodes, rawQuestion, currentTreeId, currentQuestionRoutingType) => {
    if (!rawQuestion.skipChoice) {
        return [null, false, null];
    }
    const exitOnSkip = rawQuestion.skipChoice.exitNode === true;
    const [nextNode] = treeNodes.filter((treeNode) => treeNode['@id'] === rawQuestion.skipChoice.routeToTreeNode);
    if (nextNode && nextNode.expandedNode && nextNode.expandedNode.tree) {
        let skipReturnTreeNode = null;
        skipReturnTreeNode = {
            nextTreeNode: nextNode.nextTreeNode,
            currentTreeId,
            finishAfter: ROUTING.TYPE.END === currentQuestionRoutingType,
            escape: false
        };
        return [nextNode.expandedNode.tree, exitOnSkip, skipReturnTreeNode];

    }
    return [null, exitOnSkip, null];
};

const isLoopCausingDuplicate = (resultTree, expandedNode) => {
    const [duplicate] = resultTree.questions.filter((_question) => {
        return _question.expandedNodeId === expandedNode.id;
    });
    const isDuplicate = !!duplicate;

    if (isDuplicate) {
        formatLoopResponse(resultTree, isDuplicate);
        resultTree.processTreeStatus = TREE.STATUS.IS_LOOP;
        console.log('if (isDuplicate');
        resultTree.status.finished = true;
    }
    return isDuplicate;
};

const checkFutureFailedQuestion = (state, currentQuestion) => {
    const {resultTree} = state;
    let hasMissingNextTreeOnSkipError = false;
    let hasMissingNextTreeOnSubTree = false;
    let hasMissingNextTreeOnSubMulti = false;
    if (currentQuestion.answers.some(_answer => true === _answer.chosen)) {
        if (currentQuestion.canSkip && currentQuestion.skipped) {
            const [missingNextTreeOnSkipError] = currentQuestion.answers.filter(_answer => {
                const skipReturnTreeNode = _answer.skipReturnTreeNode;
                return true === _answer.chosen && skipReturnTreeNode && null === skipReturnTreeNode.nextTreeNode;
            });
            hasMissingNextTreeOnSkipError = !!missingNextTreeOnSkipError
        }
        if (ROUTING.TYPE.SUB_TREE === currentQuestion.routingType && !currentQuestion.skipped) {
            /*const [missingNextTreeOnSubTree] = currentQuestion.answers.filter(_answer => {
                return true === _answer.chosen && null === _answer.returnTreeNode;
            });
            hasMissingNextTreeOnSubTree = !!missingNextTreeOnSubTree;//*/
        }
        if (ROUTING.TYPE.QUESTION_ANSWER === currentQuestion.routingType) {
            /*const [missingNextTree] = currentQuestion.answers.filter(_answer => {
                const typ = _answer.targetTreeNode?.expandedNode['@type'] ?? 'unknown';
                return true === _answer.chosen && null === _answer.returnTreeNode && 'SubTreeNode' === typ;
            });
            hasMissingNextTreeOnSubMulti = !!missingNextTree;//*/
        }

    }
    const errorObject = {};
    errorObject.link = `${env('TREE_MAKER_INTERFACE_URL')}/#/trees/${currentQuestion.treeId}/view`;
    errorObject.question = `"${currentQuestion?.name}"`;
    const [answer] = currentQuestion.answers.filter(_answer => _answer.chosen);
    errorObject.answer = `"${answer?.name}"`;
    if (hasMissingNextTreeOnSkipError) {
        errorObject.message = 'Missing next tree on skip error';
        handleLoopError(resultTree, errorObject);
    }
    if (hasMissingNextTreeOnSubTree) {
        errorObject.message = 'Missing next tree after sub tree error';
        handleLoopError(resultTree, errorObject);
    }
    if (hasMissingNextTreeOnSubMulti) {
        errorObject.message = 'Exit after sub tree for choice questions routed per answer';
        handleLoopError(resultTree, errorObject);
    }
};

const handleLoopError = (resultTree, errorObject) => {
    const isNotUniqueError = resultTree.loopErrors.some(_errorObject => {
        return _errorObject.link === errorObject.link &&
            _errorObject.message === errorObject.message &&
            _errorObject.question === errorObject.question &&
            _errorObject.answer === errorObject.answer
    });
    if (!isNotUniqueError) {
        resultTree.loopErrors.push(errorObject);
    }
    console.error(`${errorObject.message} for question: ${errorObject.question} with answer: ${errorObject.answer} link:> ${errorObject.link}`);
};

const formatLoopResponse = (resultTree, isDuplicate) => {
    let fixLoopQuestion = null;
    resultTree.loopErrors.forEach((_error, index) => {
        const questionText = `Error for question ${_error.question} with answer ${_error.answer}. `;
        const answerText = `[${_error.message}](${_error.link}). `;
        fixLoopQuestion = {
            answers: [{name: answerText, chosen: true}],
            isError: true,
            name: questionText,
            id: `unique_name_for_key_${index}`
        };
        resultTree.questions.push(fixLoopQuestion);
    });
    if(isDuplicate){

        fixLoopQuestion = {
            answers: [{name: 'Duplicate question in flow', chosen: true}],
            isError: true,
            name: 'Duplicate question error',
            id: `duplicate_question_in_flow_error`
        };
    }else if (null === fixLoopQuestion) {
        fixLoopQuestion = {
            answers: [{name: 'An unknown error occurred', chosen: true}],
            isError: true,
            name: 'Unknown error',
            id: `unique_name_for_key`
        };
    }

    resultTree.currentQuestion = fixLoopQuestion;
};

const formatNewQuestion = (state, chosenTreeNode, currentTreeId, data = {id: null}) => {
    const {resultTree} = state;
    const expandedNode = chosenTreeNode.expandedNode;
    let treeId = data?.id ?? null;
    if (data?.name) {
        console.warn('New tree: ', data?.name);
    }

    const nonRhetoricalQuestions = resultTree.questions.filter((question) => NODE.TYPE.TEXT_NODE !== question['@type']);
    if (null === treeId) {
        treeId = resultTree.currentQuestion.treeId;
    }
    // Find/create new question
    switch (expandedNode['@type']) {
        case NODE.TYPE.TEXT_NODE:
            // Create question placeholder
            const question = {
                ...expandedNode,
                treeNodeId: chosenTreeNode.id,
                routingType: chosenTreeNode.routingType,
                answers: [],
                skipped: false,
                wasSkipped: false,
                nextTreeNode: chosenTreeNode.nextTreeNode,
                nextTreeReference: null,
                currentTreeId,
                expandedNodeId: expandedNode.id,
                isError: false,
                navPlaceholder: expandedNode.title,
                name: expandedNode.title,
                earlyExit: META.EARLY_EXIT_DEFAULT,
                skipEarlyExit: META.EARLY_EXIT_DEFAULT,
                index: resultTree.questions.length + 1,
                displayIndex: null,
                icon: null,
                persistedReturnRoutes: resultTree.returnRoutes,
                escapeChosen: false,
                treeId
            };

            resultTree.questions.push(question);
            resultTree.currentQuestion = question;
            resultTree.hasNext = true;

            break;

        default:
            const questions = chosenTreeNode?.expandedNode?.questions ?? [];
            /* @todo const */
            let [rawQuestion] = questions;
            let check = resultTree.questions.some((question) => question.id === chosenTreeNode.id);
            if (!check) {
                const {answers, nextTreeNode} = formatAnswersForQuestion(state, rawQuestion, chosenTreeNode, currentTreeId);

                const navPlaceholder = sidenavPlaceholder(rawQuestion);
                const question = {
                    ...rawQuestion,
                    treeNodeId: chosenTreeNode.id,
                    routingType: chosenTreeNode.routingType,
                    answers,
                    skipped: false,
                    wasSkipped: false,
                    nextTreeNode,
                    nextTreeReference: null,
                    expandedNodeId: expandedNode.id,
                    isError: false,
                    currentTreeId,
                    subjectTarget: 'true' === extractFromMetadata(rawQuestion.metadata, META.SUBJECT_TARGET) || 0 === resultTree.questions.length,
                    navPlaceholder,
                    earlyExit: META.EARLY_EXIT_DEFAULT,
                    skipEarlyExit: checkEarlyExit(rawQuestion.skipChoice),
                    index: resultTree.questions.length + 1,
                    displayIndex: nonRhetoricalQuestions.length + 1,
                    subHeader: getSubHeader(rawQuestion),
                    icon: getIconLocation(rawQuestion.metadata),
                    persistedReturnRoutes: resultTree.returnRoutes,
                    sortOrder: chosenTreeNode.sortOrder,
                    escapeChosen: false,
                    treeId
                };
                resultTree.questions.push(question);
                resultTree.currentQuestion = question;
                resultTree.processTreeStatus = TREE.STATUS.COMPLETE;
                resultTree.hasPrevious = resultTree.questions.indexOf(resultTree.currentQuestion) > 0;
                resultTree.hasNext = false;
            }
    }
};

const getIndexAndQuestionsLength = (resultTree) => {
    const index = resultTree.questions.findIndex(question => question.id === resultTree.currentQuestion.id);
    const questionsLength = resultTree.questions.length;
    return [index, questionsLength];
};

const updateOpenAnswer = (answer, openAnswer, question) => {
    let chosen = false;
    const questionType = question['@type'];
    switch (questionType) {
        case QUESTION_NODE.TYPE.TEXT:
            question.skipped = openAnswer.skipped;
        /* falls through */
        case QUESTION_NODE.TYPE.CHOICE:
        case QUESTION_NODE.TYPE.SMILEY:
        case QUESTION_NODE.TYPE.SLIDER:
            if (openAnswer.hasOwnProperty('proxy')) {
                chosen = !(answer.multiple && 'selected' === answer.styleClass);
            } else {
                answer.name = openAnswer.text;
                chosen = 0 < openAnswer.text.length;
                if (answer.other && false === chosen) {
                    chosen = null;
                }
            }

            break;
        case QUESTION_NODE.TYPE.DATE:
            question.skipped = openAnswer.skipped;
            answer.name = openAnswer.date;
            chosen = 0 < openAnswer.date.length;
            break;
        case QUESTION_NODE.TYPE.FILE_UPLOAD:
            let name = '';
            chosen = false;
            switch (true) {
                case openAnswer.checked:
                    chosen = true;
                    name = 'Geen bestanden aanwezig';
                    break;
                case question.mediaObjects && question.mediaObjects.length > 0:
                    name = getUploadedFileList(question);
                    chosen = true;
                    break;
                default:
                    name = '';
            }
            question.skipped = openAnswer.skipped;
            answer.checked = openAnswer.checked;
            answer.name = name;
            break;
        default:
            console.error(`question type ${questionType} not handled`);
    }
    return chosen;
};

const deleteAfter = (resultTree, index) => {
    const deletedQuestions = resultTree.questions.splice(index + 1);
    const subjectDeleted = deletedQuestions.some(question => {
        return question.answers.some(answer => resultTree.subject === answer.name)
    });
    if (subjectDeleted) {
        resultTree.subject = '-';
        resultTree.category = '-';
        resultTree.categoryQuestion = '-';
    }
    resultTree.returnRoutes = resultTree.currentQuestion.persistedReturnRoutes;
};

const setFirstUnansweredIndex = (state, includeSocket = true) => {

    const {resultTree} = state;
    resultTree.firstUnansweredIndex = resultTree.questions
        .findIndex((question) => {
            if (NODE.TYPE.TEXT_NODE === question['@type'] || question.escapeChosen) {
                return false;
            }
            return question.answers.every((answer) => {
                if ('' === answer.name && answer.chosen && answer.other) {
                    return true;
                }
                return !answer.chosen;
            });
        });
    if (-1 === resultTree.firstUnansweredIndex) {
        resultTree.firstUnansweredIndex = resultTree.questions.length - 1;
    }
    if (includeSocket) {
        state.screen.screenShareOutOfSync = true;
    }
};

const resultSlice = createSlice({
    name: 'result',
    initialState: resultAdapter.getInitialState(resultInitialState),
    reducers: {
        initialise: {
            reducer: (state) => {
                state.resultTree.start = false;
                return state
            },
            prepare: (payload) => ({
                payload: {
                    ...payload,
                    id: entryId
                }
            }),
        },
        processData: (state, {payload: {treeData, currentTreeId}}) => {
            const data = JSON.parse(treeData);
            state.treeNodes = data.treeNodes;
            state.treeNodeCount = data.treeNodes.length;
            state.resultTree.idCurrentTree = data.id;
            const [visibleTreeNode] = data.treeNodes.filter(treeNode => treeNode.visible);
            if (isLoopCausingDuplicate(state.resultTree, visibleTreeNode.expandedNode)) {
                return;
            }
            formatNewQuestion(state, visibleTreeNode, currentTreeId, data);
            setFirstUnansweredIndex(state);
        },
        updateTreeNodes: (state, {payload: {treeData}}) => {
            const data = JSON.parse(treeData);
            state.treeNodes = data.treeNodes;
            state.treeNodeCount = data.treeNodes.length;
            setFirstUnansweredIndex(state);
        },
        changeTreeReference: (state, {payload: {currentAnswer, nextTreeReference, openAnswer = {}, error = {}}}) => {
            const {resultTree} = state;
            const [index,] = getIndexAndQuestionsLength(resultTree);
            if (error && 0 !== Object.keys(error).length) {
                resultTree.processTreeStatus = error.type;
                resultTree.errorMessage = [error.message];
                resultTree.errorLink = `${env('TREE_MAKER_INTERFACE_URL')}/#/trees/${error.linkId}/view`;
                return;
            }
            const dontChangeNextTreeReference = ((null === nextTreeReference && openAnswer.hasOwnProperty('text') && currentAnswer.other) || openAnswer?.dontChange === true);
            resultTree.questions.forEach(question => {
                question.earlyExit = META.EARLY_EXIT_DEFAULT;
                if (question.id === currentAnswer.questionId) {
                    if (openAnswer && openAnswer.deleteAfter) {
                        question.escapeChosen = false;
                    }
                    question.answers.forEach(answer => {
                        let chosen = answer.chosen;
                        if (answer.id === currentAnswer.id) {
                            chosen = answer.multiple ? !answer.chosen : true;
                            if (0 !== Object.keys(openAnswer).length && true !== openAnswer.ignore) {
                                chosen = updateOpenAnswer(answer, openAnswer, question);
                            }
                        } else if (!answer.multiple) {
                            chosen = false;
                            answer.name = answer.other ? '' : answer.name;
                        }
                        answer.chosen = chosen;
                        answer.styleClass = false === chosen ? 'available' : 'selected';
                    });
                    if (!dontChangeNextTreeReference) {
                        question.nextTreeReference = nextTreeReference;
                    }
                    question.nextTreeNode = currentAnswer.nextTreeNode;
                    resultTree.currentQuestion = question;
                    resultTree.currentQuestion.earlyExit = currentAnswer.earlyExit;
                }

            });
            if (openAnswer && openAnswer.deleteAfter) {
                deleteAfter(resultTree, index);
                resultTree.currentQuestion.escapeChosen = false;
            }
            if (!dontChangeNextTreeReference) {
                resultTree.currentQuestion.nextTreeReference = nextTreeReference;
            }
            const hasChosen = resultTree.currentQuestion.answers.some((answer) => answer.chosen);
            const isProxy = openAnswer.hasOwnProperty('proxy');
            resultTree.hasNext = hasChosen && !isProxy;
            setFirstUnansweredIndex(state, openAnswer?.includeSocket !== false);
            if (resultTree.currentQuestion.subjectTarget && ('-' === resultTree.category || resultTree.categoryQuestion === resultTree.currentQuestion.id)) {
                resultTree.subject = currentAnswer.name;
                resultTree.category = currentAnswer.specialism;
                resultTree.categoryQuestion = resultTree.currentQuestion.id;
            }
            if (openAnswer?.escape) {
                resultTree.processTreeStatus = TREE.STATUS.REFRESH;
            }
        },
        chooseFlowComplexity: (state) => {
            const {resultTree} = state;
            resultTree.processTreeStatus = TREE.STATUS.CHOOSE_FLOW_COMPLEXITY;
            resultTree.flowType = null;
        },
        setFlowComplexity: (state, {payload}) => {
            const {complexity} = payload;
            const {resultTree} = state;
            resultTree.flowType = complexity;
        },
        nextQuestion: (state, {payload =  {}}) => {
            const {resultTree, treeNodes} = state;
            if (!resultTree) {
                return
            }
            const {currentQuestion} = resultTree;
            if (payload?.escape) {
                resultTree.processTreeStatus = TREE.STATUS.INSERT;
                resultTree.returnRoutes.push({
                    nextTreeNode: null,
                    currentTreeId: '',
                    finishAfter: false,
                    hasError: false,
                    escape: true
                });
              //  return;
            }
            if (!resultTree.hasNext) {
                return;
            }
            const [index, questionsLength] = getIndexAndQuestionsLength(resultTree);
            const nextIndex = index + 1;
            if (questionsLength <= nextIndex) {
                checkFutureFailedQuestion(state, currentQuestion);
                const [returnTreeNode] = currentQuestion.answers.filter((answer) => {
                    return answer.returnTreeNode && answer.chosen && !currentQuestion.skipped;
                }).map((answer) => answer.returnTreeNode);
                const [skipReturnTreeNode] = currentQuestion.answers.filter((answer) => {
                    return answer.skipReturnTreeNode && answer.chosen && currentQuestion.skipped;
                }).map((answer) => answer.skipReturnTreeNode);

                if (returnTreeNode && !currentQuestion.skipped) {
                    resultTree.returnRoutes.push(returnTreeNode);
                } else if (skipReturnTreeNode) {
                    resultTree.returnRoutes.push(skipReturnTreeNode);
                }
            }

            if (currentQuestion.earlyExit.exit || (currentQuestion.skipEarlyExit.exit && currentQuestion.skipped)) {
                const earlyExit = currentQuestion.earlyExit.exit ? currentQuestion.earlyExit : currentQuestion.skipEarlyExit;
                resultTree.processTreeStatus = TREE.STATUS.EXIT;
                resultTree.earlyExitDescription = earlyExit.description;
                resultTree.currentQuestion = null;
                return;
            }

            if (questionsLength > nextIndex) {
                const nextQuestion = resultTree.questions[nextIndex];
                resultTree.processTreeStatus = TREE.STATUS.REFRESH;
                resultTree.currentQuestion = nextQuestion;
            } else if (null === currentQuestion.nextTreeNode && currentQuestion.routingType !== ROUTING.TYPE.END && currentQuestion.routingType !== ROUTING.TYPE.QUESTION_ANSWER) {
                const skipped = resultTree.currentQuestion.skipped;
                const routingType = resultTree.currentQuestion.routingType;
                const isSubTree = ROUTING.TYPE.SUB_TREE === routingType;
                let errorMessage = '';
                switch (true) {
                    case isSubTree:
                        errorMessage = 'No next tree after sub tree';
                        resultTree.processTreeStatus = TREE.STATUS.MISSING_NEXT_TREE_SUB_TREE_ERROR;
                        break;
                    case skipped:
                        errorMessage = 'No next tree on skip';
                        resultTree.processTreeStatus = TREE.STATUS.MISSING_NEXT_TREE_SKIP_ERROR;
                        break;
                    default:
                        errorMessage = 'No next tree';
                        resultTree.processTreeStatus = TREE.STATUS.MISSING_NEXT_TREE_ERROR;
                }
                resultTree.errorMessage = [errorMessage, `Q: "${currentQuestion?.name}"`];
                resultTree.errorLink = `${env('TREE_MAKER_INTERFACE_URL')}/#/trees/${resultTree.idCurrentTree}/view`;
                resultTree.currentQuestion = null;
            } else {
                const currentRoutingType = currentQuestion.routingType;
                const isRouteEnd = ROUTING.TYPE.END === currentRoutingType && (!currentQuestion.skipChoice || (currentQuestion.skipChoice && !currentQuestion.skipped));
                const isSkipEnd = currentQuestion.skipChoice && currentQuestion.skipChoice.exitNode && currentQuestion.skipped;
                let userEscaped = false;
                const chosenAnswer = currentQuestion.answers.find(_answer => _answer.chosen)
                switch (true) {
                    // Last question of the Tree
                    case isRouteEnd || isSkipEnd:
                        let lastReturnRoute;
                        do {
                            lastReturnRoute = resultTree.returnRoutes.pop();
                            if (lastReturnRoute?.escape) {
                                userEscaped = true;
                                break;
                            }
                        } while (resultTree.returnRoutes.length > 0 && lastReturnRoute.finishAfter);

                        if (lastReturnRoute && !userEscaped) {
                            const [nextNode] = treeNodes.filter((treeNode) => treeNode['@id'] === lastReturnRoute.nextTreeNode);
                            if (!nextNode) {
                                resultTree.processTreeStatus = TREE.STATUS.INSERT;
                                resultTree.returnRoutes.push(lastReturnRoute);
                                return
                            }
                            // If we return to the same question we get double questions
                            if (nextNode.id !== currentQuestion.treeNodeId) {
                                if (isLoopCausingDuplicate(resultTree, nextNode.expandedNode)) {
                                    return;
                                }
                                formatNewQuestion(state, nextNode, lastReturnRoute.currentTreeId);
                                break;
                            }
                        }
                        const returnLength = resultTree.returnRoutes?.length ?? 0;
                        if (returnLength < 1 || userEscaped) {
                            if(!userEscaped && '-' === resultTree.category){
                                resultTree.processTreeStatus = TREE.STATUS.MISSING_CATEGORY_QUESTION_ERROR;
                                resultTree.errorMessage = [`Missing category question!`, `Q: "${currentQuestion?.name}"`, `A: "${chosenAnswer?.name}"`];
                                resultTree.errorLink = `${env('TREE_MAKER_INTERFACE_URL')}/#/trees/${resultTree.idCurrentTree}/view`;
                                resultTree.currentQuestion = null
                            }else {
                                resultTree.status.finished = true;
                            }
                        } else {
                            // ????
                            resultTree.processTreeStatus = TREE.STATUS.INSERT;
                        }
                        break;

                    case ROUTING.TYPE.QUESTION_ANSWER === currentRoutingType:
                        const choiceRouting = chosenAnswer.choiceRouting
                        if (!choiceRouting) {
                            resultTree.processTreeStatus = TREE.STATUS.ROUTING_ERROR
                            resultTree.errorMessage = [`Missing next question!`, `Q: "${currentQuestion?.name}"`, `A: "${chosenAnswer?.name}"`];
                            resultTree.errorLink = `${env('TREE_MAKER_INTERFACE_URL')}/#/trees/${resultTree.idCurrentTree}/view`;
                            resultTree.currentQuestion = null
                            break;
                        }
                        if (choiceRouting.exitNode) {
                            if('-' === resultTree.category){
                                resultTree.errorMessage = [`Missing category question!`, `Q: "${currentQuestion?.name}"`, `A: "${chosenAnswer?.name}"`];
                                resultTree.processTreeStatus = TREE.STATUS.MISSING_CATEGORY_QUESTION_ERROR;
                                break;
                            } else {
                                resultTree.status.finished = true;
                            }
                            break;
                        }

                        const [nextNode] = treeNodes.filter((treeNode) => treeNode['@id'] === choiceRouting.routeToTreeNode);
                        if (!nextNode) {
                            resultTree.processTreeStatus = TREE.STATUS.INSERT;
                            return
                        }

                        const expandedNode = nextNode.expandedNode

                        if (expandedNode['@type'] === NODE.TYPE.SUB_TREE_NODE) {
                            // resultTree.processTreeStatus = TREE.STATUS.INSERT
                            // @todo: load sub tree nodes
                            const tempNextNode = treeNodes.find(_treeNode => _treeNode['@id'] === chosenAnswer.nextTreeNode)

                            if (tempNextNode.routingType === ROUTING.TYPE.END) {
                                resultTree.processTreeStatus = TREE.STATUS.INSERT
                                break;
                            }

                            const nextTreeNode = treeNodes.find(_treeNode => _treeNode['@id'] === tempNextNode.nextTreeNode);
                            if (!nextTreeNode) {
                                resultTree.processTreeStatus = TREE.STATUS.INSERT;
                                return
                            }
                            if (isLoopCausingDuplicate(resultTree, nextTreeNode.expandedNode)) {
                                return;
                            }
                            formatNewQuestion(state, nextTreeNode, currentQuestion.currentTreeId);
                            resultTree.processTreeStatus = TREE.STATUS.COMPLETE
                        } else {
                            if (isLoopCausingDuplicate(resultTree, nextNode.expandedNode)) {
                                return;
                            }
                            formatNewQuestion(state, nextNode, currentQuestion.currentTreeId);
                            resultTree.processTreeStatus = TREE.STATUS.COMPLETE
                        }

                        break;

                    case ROUTING.TYPE.SUB_TREE === currentRoutingType && !currentQuestion.skipped:
                        // @todo
                        const tempNextNode = treeNodes.find(_treeNode => _treeNode['@id'] === currentQuestion.nextTreeNode);
                        if (!tempNextNode) {
                            resultTree.processTreeStatus = TREE.STATUS.INSERT;
                            return
                        }
                        const nextTreeNode = treeNodes.find(_treeNode => _treeNode['@id'] === tempNextNode.nextTreeNode)
                        if (!nextTreeNode) {
                            resultTree.processTreeStatus = TREE.STATUS.INSERT;
                            return
                        }
                        if (isLoopCausingDuplicate(resultTree, nextTreeNode.expandedNode)) {
                            return;
                        }
                        formatNewQuestion(state, nextTreeNode, currentQuestion.currentTreeId);
                        resultTree.processTreeStatus = TREE.STATUS.COMPLETE;

                        break;

                    default:
                        const skipRoute = getCheckSkipRoute(resultTree, treeNodes);
                        if (!(currentQuestion.skipped) && currentQuestion.nextTreeReference && currentQuestion.nextTreeReference !== currentQuestion.currentTreeId) {
                            resultTree.processTreeStatus = TREE.STATUS.INSERT
                        } else {
                            const nextTreeNode = skipRoute || currentQuestion.nextTreeNode;
                            const [nextNode] = treeNodes.filter((treeNode) => treeNode['@id'] === nextTreeNode);

                            if (!nextNode) {
                                resultTree.processTreeStatus = TREE.STATUS.INSERT;
                                return
                            }

                            if (isLoopCausingDuplicate(resultTree, nextNode.expandedNode)) {
                                return;
                            }
                            formatNewQuestion(state, nextNode, currentQuestion.currentTreeId);
                            resultTree.processTreeStatus = TREE.STATUS.COMPLETE;
                        }

                }
            }

            resultTree.hasNext = hasNext(resultTree)
            setFirstUnansweredIndex(state);
            resultTree.hasPrevious = true;
        },
        updateEscapeChosen: (state, {payload =  {}}) => {
            const {currentQuestion} = state.resultTree;
            currentQuestion.escapeChosen = true;
            state.resultTree.questions.map((question) => {
                if(question.id ===currentQuestion.id){
                    question.escapeChosen = true;
                }
                return question;
            });
        },
        previousQuestion: (state) => {
            const {resultTree} = state;
            if (!resultTree || !resultTree.hasPrevious) {
                return
            }

            let {currentQuestion} = resultTree;
            let previousQuestion

            if (currentQuestion === null) {
                // at the end probably, get the last question
                previousQuestion = resultTree.questions.slice(-1)[0]
                resultTree.processTreeStatus = TREE.STATUS.COMPLETE
            } else {
                const index = resultTree.questions.findIndex(question => question.id === currentQuestion.id);
                previousQuestion = resultTree.questions[index - 1];

                const sameTreeId = previousQuestion.currentTreeId === currentQuestion.currentTreeId;
                resultTree.processTreeStatus = sameTreeId ? TREE.STATUS.COMPLETE : TREE.STATUS.REFRESH;
            }

            resultTree.currentQuestion = previousQuestion;

            resultTree.hasPrevious = resultTree.questions.indexOf(resultTree.currentQuestion) > 0;
            resultTree.hasNext = hasNext(resultTree)
            setFirstUnansweredIndex(state);
        },
        specificQuestion: (state, {payload: index}) => {
            const {resultTree} = state;
            const safeIndex = Math.min(index, resultTree.firstUnansweredIndex);
            resultTree.currentQuestion = resultTree.questions[safeIndex];
            resultTree.hasPrevious = safeIndex > 0;
            resultTree.hasNext = hasNext(resultTree);
            if (TREE.STATUS.TERMINATED.includes(resultTree.processTreeStatus)) {
                resultTree.processTreeStatus = TREE.STATUS.COMPLETE;
            }
            setFirstUnansweredIndex(state);
        },
        refreshTree: (state) => {
            state.resultTree.processTreeStatus = TREE.STATUS.REFRESH;
        },
        setTreeStatus: (state, {payload}) => {
            Object.assign(state.resultTree.status, payload);
        },
        resetOutOfSync: (state) => {
            state.screen.screenShareOutOfSync = false;
        },
        updateRole: (state, {payload: {role}}) => {
            state.screen.role = role;
        },
        updateCode: (state, {payload: {code, codeDigit}}) => {
            state.screen.screenShareCode = `${codeDigit}-${code}`;
        },
        updateAdminName: (state, {payload: {adminName}}) => {
            state.screen.adminName = adminName;
        },
        updateLoadedTrees: (state, {payload: {treeNodeReference}}) => {
               if (treeNodeReference && !state.resultTree.loadedTrees.includes(treeNodeReference)) {
                state.resultTree.loadedTrees.push(treeNodeReference);
            }
        },
        updateDisconnecting: (state, {payload: {disconnecting}}) => {
            state.screen.disconnecting = disconnecting;
        },
        updateScreen: (state, {payload}) => {
            Object.assign(state.screen, payload);
        },
        setResendEmailStatus: (state, {payload}) => {
            state.mailStatus = payload.saveDialog;
        },
        setLoading: (state, {payload}) => {
            state.resultTree.loading = Object.assign({}, state.resultTree.loading, payload.loading);
        },
    },
    extraReducers: {
        [initialiseApp.fulfilled]: (state, {payload}) => {
            const trees = Object.values(payload.trees)
            const treePublication = trees[0]
            state.tree = state.resultTree.tree = treePublication.tree;
            state.treePublication = treePublication['@id']
            state.resultTree.start = false
        },
        'trees/getTree/fulfilled': (state, {payload}) => {
            if (!state.treePublication) {
                const trees = Object.values(payload.trees)
                const treePublication = trees[0]
                state.resultTree.tree = treePublication.tree;
                state.treePublication = treePublication['@id']
            }
        },
        'client/uploadFile/fulfilled': (state, {payload}) => {
            const mediaObjects = state.resultTree.currentQuestion.mediaObjects ?? []
            state.resultTree.currentQuestion.mediaObjects = mediaObjects.concat(Object.values(payload.mediaObjects))
            let _currentAnswer = state.resultTree.currentQuestion.answers[0];
            _currentAnswer.name = getUploadedFileList(state.resultTree.currentQuestion);
            _currentAnswer.chosen = true;
            _currentAnswer.checked = false;
            _currentAnswer.skipped = false;
            state.resultTree.currentQuestion.skipped = false;
            state.resultTree.currentQuestion.answers = [_currentAnswer];

            state.resultTree.questions.map(_question => {
                if (_question.id === state.resultTree.currentQuestion.id) {
                    _question.mediaObjects = state.resultTree.currentQuestion.mediaObjects
                    _question.answers = state.resultTree.currentQuestion.answers;
                }

                return _question
            })
            state.resultTree.hasNext = true;
            setFirstUnansweredIndex(state);
        },
        'client/deleteFile/fulfilled': (state, {payload}) => {
            state.resultTree.currentQuestion.mediaObjects = state.resultTree.currentQuestion.mediaObjects.filter((mediaObject) => mediaObject.id !== payload.deletedId)
            state.resultTree.questions.map(_question => {
                if (_question.id === state.resultTree.currentQuestion.id) {
                    _question.mediaObjects = state.resultTree.currentQuestion.mediaObjects
                }
                return _question;
            });
            state.resultTree.hasNext = state.resultTree.currentQuestion.mediaObjects.length > 0;
            setFirstUnansweredIndex(state);
        },
        'client/postMagicLink/fulfilled': (state) => {
            state.resultTree.status.restored = false;
        },
        'client/deleteFile/rejected': (state, {payload}) => {
            state.resultTree.currentQuestion.mediaObjects = state.resultTree.currentQuestion.mediaObjects.filter((mediaObject) => mediaObject.id !== payload.deletedId)
            state.resultTree.questions.map(_question => {
                if (_question.id === state.resultTree.currentQuestion.id) {
                    _question.mediaObjects = state.resultTree.currentQuestion.mediaObjects
                }
                return _question;
            });
            state.resultTree.hasNext = state.resultTree.currentQuestion.mediaObjects.length > 0;
            setFirstUnansweredIndex(state);
        },
        'client/retrieveCase/fulfilled': (state, {payload: {tree, treePublication, resultTree}}) => {
            resultTree.status = resultTree.status ?? {};
            resultTree.status.mail_expired = state.resultTree.status?.mail_expired ?? true;
            resultTree.status.restored = state.resultTree.status.restored;
            resultTree.status.mail_dialog_open = state.resultTree.status.mail_dialog_open;
            state.resultTree = resultTree;
            state.tree = tree;
            state.treePublication = treePublication;
        },
        'trees/getTreeByTreePath/fulfilled': (state, {payload}) => {
            if (payload.trees) {
                const [trees] = Object.values(payload.trees);
                const {tree} = trees;
                state.resultTree.tree = tree;
            }
        },
        'client/createCase/fulfilled': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newDossierLoading: false,
                    saveDialogStateCase: null,
                    moreCallsLoading: false
                })
        },
        'client/createCase/rejected': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newDossierLoading: false,
                    saveDialogStateCase: null,
                    moreCallsLoading: false
                })
        },
        'client/updateCase/fulfilled': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newDossierLoading: false,
                    saveDialogStateCase: null,
                    moreCallsLoading: false
                })
        },
        'client/updateCase/rejected': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newDossierLoading: false,
                    saveDialogStateCase: null,
                    moreCallsLoading: false
                })

        },
        'client/createUser/fulfilled': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newClientLoading: false,
                    saveDialogStateClient: null,
                    moreCallsLoading: true
                });
        },
        'client/createUser/rejected': (state) => {
            state.resultTree.loading = Object.assign(
                {},
                state.resultTree.loading,
                {
                    newClientLoading: false,
                    saveDialogStateClient: null,
                    moreCallsLoading: true
                });
        },
        'GI_LAW_WEB_SOCKET_PREFIX::MESSAGE': (state, {payload}) => {
            const {result, client, admin, adminName, connectionCode, disconnect} = JSON.parse(payload.message);
            if (state.screen.screenShareCode === connectionCode) {
                if (admin && adminName) {
                    state.screen.shareScreenAdminName = adminName;
                }
                if (disconnect) {
                    state.screen.disconnecting = true;
                }
                if (result && result.resultTree?.currentQuestion && ((client && state.screen.role.admin) || (admin && state.screen.role.client))) {
                    if (state.screen.role.admin) {
                        state.screen.canSend = true;
                    }
                    const {resultTree, id, treeNodes, tree, treePublication} = result;
                    if (id) {
                        state.id = id;
                    }
                    if (tree) {
                        state.tree = tree;
                    }
                    if (resultTree) {
                        state.resultTree = resultTree;
                    }
                    if (treeNodes) {
                        state.treeNodes = treeNodes;
                    }
                    if (treePublication) {
                        state.treePublication = treePublication;
                    }

                }

            }
        },
        'GI_LAW_WEB_SOCKET_PREFIX::OPEN': (state) => {
            state.screen.screenShareOutOfSync = true;
        },
        'GI_LAW_WEB_SOCKET_PREFIX::CONNECT': (state) => {
            state.screen.disconnecting = false;
        },
    },
});

const hasNext = (resultTree) => {
    const {currentQuestion} = resultTree

    if (!currentQuestion) {
        return false
    }

    if (NODE.TYPE.TEXT_NODE === currentQuestion['@type']) {
        return true
    }

    if (QUESTION_NODE.TYPE.FILE_UPLOAD === currentQuestion['@type'] && currentQuestion.hasOwnProperty('mediaObjects') && currentQuestion.mediaObjects.length > 0) {
        return true
    }

    return currentQuestion.answers.some((answer) => {
        if ('' === answer.name && answer.chosen && answer.other) {
            return false;
        }
        return answer.chosen;
    })
};

export const {
    initialise: initialiseApplication,
    processData: processTreeData,
    changeTreeReference: updateTreeReference,
    nextQuestion: moveToNextQuestion,
    chooseFlowComplexity: moveToChooseFlowComplexity,
    setFlowComplexity: updateFlowComplexity,
    previousQuestion: moveToPreviousQuestion,
    updateTreeNodes: refreshTreeNodes,
    specificQuestion: moveToSpecificQuestion,
    updateEscapeChosen: updateEscapeChosenCurrentQuestion,
    refreshTree: refreshTreeData,
    setTreeStatus: updateTreeStatus,
    resetOutOfSync: revertOutOfSync,
    updateRole: setRole,
    updateCode: setCode,
    updateAdminName: setAdminName,
    updateLoadedTrees: saveLoadedTrees,
    updateDisconnecting: setDisconnecting,
    setResendEmailStatus: updateMailStatus,
    updateScreen: setScreenParams,
    setLoading:setNewClientOrDossierLoading,
} = resultSlice.actions;

export default resultSlice.reducer;
/*
export const checkQueryInitialisation = () => createSelector([
        state => state.client.clients.entities,
    ],
    (clientCases) => {
        const [cases] = Object.values(clientCases);
        return cases?.clientCases?.length;
    }
);//*/
