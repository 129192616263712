import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import env from "@beam-australia/react-env";
import {jws} from "jsrsasign";
import {normalize} from "normalizr";
import clientApi from "../../services/clientApi";
import {clientEntity, caseEntity, mediaObjectEntity} from "../../schemas";
import {DateTime} from "luxon";
import crypto from "crypto";
import LocalStorageService from "../../services/storage/LocalStorageService";
import {ROLES} from "../../app/constants";

const clientAdapter = createEntityAdapter();
const clientCaseAdapter = createEntityAdapter();
const mediaAdapter = createEntityAdapter();
const screenShareAdapter = createEntityAdapter();

const parseAndValidateAccessToken = (accessToken, verify = true) => {
    if (verify === false || jws.JWS.verifyJWT(accessToken, env('JWT_PUBLIC_KEY'), {alg: ["RS256"]})) {
        const token = jws.JWS.parse(accessToken)
        return token.payloadObj
    }
    return false
};

export const createUser = createAsyncThunk("client/createUser", async ({formData, id = null}, {rejectWithValue}) => {
        try {
            let results;
            if (id) {
                results = await clientApi.patch(`/api/users/${id}`, formData);
            } else {
                results = await clientApi.post('/api/public/users', formData);
            }
            const normalized = normalize(results.data, clientEntity);
            return normalized.entities;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getUser = createAsyncThunk("client/getUser", async ({id}) => {
    const results = await clientApi.get(`/api/users/${id}`, {});
    const normalized = normalize(results.data, clientEntity);
    return normalized.entities;
});

export const getUserByEmail = createAsyncThunk("client/getUserByEmail", async ({email}) => {
    const results = await clientApi.get(`/api/public/users/${email}/by-email`, {});
    const normalized = normalize(results.data, clientEntity);
    return normalized.entities;
});

export const loginCheck = createAsyncThunk("client/loginCheck", async ({authUri}, {rejectWithValue}) => {
        try {
            const result = await clientApi.rawGet(authUri);
            return result.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadFile = createAsyncThunk("client/uploadFile", async ({formData, existingClient}) => {
    const uploadUri = existingClient ? '/api/media_objects' : '/api/public/media_objects';
    const results = await clientApi.post(uploadUri, formData);
    const normalized = normalize(results.data, mediaObjectEntity);

    return normalized.entities;
});

export const deleteFile = createAsyncThunk("client/deleteFile", async ({id}, {rejectWithValue}) => {
    try {
        await clientApi.delete(`/api/media_objects/${id}`, {});
        return {deletedId: id};
    } catch (error) {
        const result = Object.assign({}, error.response.data, error.response, {deletedId: id});
        return rejectWithValue(result);
    }
});

export const createCase = createAsyncThunk("client/createCase", async ({formData}, {rejectWithValue}) => {
    try{
        const results = await clientApi.post('/api/client_cases', formData);
        const normalized = normalize(results.data, caseEntity);
        return normalized.entities;
    }catch (error) {
        const result = Object.assign({}, error.response.data, error.response);
        return rejectWithValue(result);
    }
});

export const postMagicLink = createAsyncThunk("client/postMagicLink", async ({id, formData}) => {
    const results = await clientApi.post(`/api/client_cases/${id}/magic_link`, formData);
    return results.data;
});

export const getMyDossiersLink = createAsyncThunk("client/getMyDossiersLink", async ({id}) => {
    const results = await clientApi.get(`/api/users/${id}/my-dossiers`, {});
    return results.data;
});

export const updateCase = createAsyncThunk("client/updateCase", async ({uri, formData}) => {
    const results = await clientApi.patch(uri, formData);
    return results.data;
});

export const retrieveCase = createAsyncThunk("client/retrieveCase", async ({clientCaseId}) => {
    const results = await clientApi.get(`/api/client_cases/${clientCaseId}`);
    return results.data;
});

export const getAvailableAdmins = createAsyncThunk("client/getMagicLink", async () => {
    const results = await clientApi.get(`/api/public/user/available_admins`, {});
    return results.data;
});

export const shareScreen = createAsyncThunk("client/shareScreen", async ({formData}) => {
    const results = await clientApi.post('/api/public/screen_shares', formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);
    return results.data;
});

export const getSharedScreen = createAsyncThunk("client/getSharedScreen", async ({id}) => {
    const results = await clientApi.get(`/api/public/screen_shares/${id}`, {});
    return results.data;
});

export const updateScreenShare = createAsyncThunk("client/updateScreenShare", async ({id, formData}) => {
    await clientApi.patch(`/api/public/screen_shares/${id}`, formData);
    return {...formData, id};
});

const clientInitialState = {
    token: {},
    speakText: true,
    modals: {
        saveQuestionFlow: {
            disabled: false,
            open: false,
            initialised: false,
        },
        contactHelpCenter: {
            open: false,
            start: false,
            cancel: false,
            sharing: false,
            adminSharing: false,
        }
    },
    error: '',
    existingClient: false,
    isAnonymous: false,
    currentCaseId: undefined,
    saveClientCase: false,
    browserHash: crypto.createHash('sha1').update((Date.now() >>> 0).toString(2)).digest('hex'),
    currentScreenCode: {
        code: null,
        codeDigit: null
    },
    availableAdmins: 0,
    currentScreenId: null,
    currentScreenCreatedAt: null,
    rhvClient: {
        isRhvClient: false,
        rhvId: null,
        rhvFromLink: false,
        notAutoAccepted: false,
        origin: 'flow',
        isFrame: false
    },
    clients: clientAdapter.getInitialState(),
    clientCases: clientCaseAdapter.getInitialState(),
    clientMedia: mediaAdapter.getInitialState(),
    screenShares: screenShareAdapter.getInitialState(),
};

const clientSlice = createSlice({
    name: 'client',
    initialState: clientInitialState,
    reducers: {
        onToggleSpeakText: (state) => {
            state.speakText = !state.speakText;
        },
        onContactHelpCenter: (state, {payload: {open}}) => {
            state.modals.contactHelpCenter.open = open
        },
        onShareScreenStart: (state, {payload: {start, cancel, sharing}}) => {
            state.modals.contactHelpCenter.start = start;
            state.modals.contactHelpCenter.cancel = cancel;
            state.modals.contactHelpCenter.sharing = sharing;
        },
        setScreenSharingParams: (state, {payload}) => {
            Object.assign(state.modals.contactHelpCenter, payload);
        },
        logOut: (state) => {
            LocalStorageService.clearToken();
        },
        setRhvClient: (state, {payload}) => {
            state.rhvClient = payload
        },
    },
    extraReducers: {
        [createUser.fulfilled]: (state, {payload}) => {
            clientAdapter.addMany(state.clients, payload.client);
            const [client] = Object.values(payload.client);
            state.existingClient = true;
            state.isAnonymous = client.email.includes(client.borwserHash);
            state.browserHash = client.browserHash;
            if (client.token) {
                state.token = parseAndValidateAccessToken(client.token, false);
            }
        },
        [getUser.fulfilled]: (state, {payload}) => {
            clientAdapter.addMany(state.clients, payload.client);
            const [client] = Object.values(payload.client);//*/
            if (client?.browserHash) {
                state.browserHash = client.browserHash;
                state.existingClient = true;
            }
            state.isAnonymous = client.email.includes(client.browserHash);
        },
        [loginCheck.fulfilled]: (state, {payload}) => {
            state.token = parseAndValidateAccessToken(payload.token, false);
        },
        [uploadFile.fulfilled]: (state, {payload}) => {
            mediaAdapter.upsertMany(state.clientMedia, payload.mediaObjects);
            if (!state.token.id) {
                const [mediaObject] = Object.values(payload.mediaObjects);
                state.token = parseAndValidateAccessToken(mediaObject.createdBy.token, false);
            }
        },
        [createCase.fulfilled]: (state, {payload}) => {
            clientCaseAdapter.addMany(state.clientCases, payload.case)
            const clientCases = Object.values(payload.case)
            const clientCase = clientCases[0]
            state.currentCaseId = clientCase.id;
            state.saveClientCase = false;
        },
        [updateCase.fulfilled]: (state) => {
            state.saveClientCase = false;
        },
        [shareScreen.fulfilled]: (state, {payload}) => {
            screenShareAdapter.addOne(state.screenShares, payload);
            const {codeDigit, code, createdAt, id} = payload;
            state.currentScreenCode = {
                code,
                codeDigit
            };
            state.currentScreenId = id;
            state.currentScreenCreatedAt = createdAt;
        },
        [deleteFile.fulfilled]: (state, {payload}) => {
            let {ids, entities} = state.clientMedia;
            if (ids.includes(payload.deletedId)) {
                delete entities[payload.deletedId];
                state.clientMedia.ids = ids.filter((id) => id !== payload.deletedId);
            }
        },
        [retrieveCase.fulfilled]: (state, {payload}) => {
            state.existingClient = true;
            const {id, createdBy} = payload;
            state.currentCaseId = id;
            state.browserHash = createdBy.browserHash;
            let clientCase = Object.assign({}, payload);
            delete clientCase.resultTree;
            clientCaseAdapter.addOne(state.clientCases, clientCase);
        },
        [updateScreenShare.fulfilled]: (state, {payload}) => {
            if (payload.finishedAt || payload?.clientClosedAt || payload?.adminClosedAt) {
                state.currentScreenCode = {
                    code: null,
                    codeDigit: null
                };
                state.currentScreenId = null;
                state.currentScreenCreatedAt = null;
                const {screenShares} = state;
                if (screenShares.ids.includes(payload?.id)){
                    delete screenShares.entities[payload?.id];
                    screenShares.ids = screenShares.ids.filter( id => id !== payload?.id)
                }
            }
        },
        [getAvailableAdmins.fulfilled]: (state, {payload}) => {
            state.availableAdmins = payload.total;
        },
        'GI_LAW_WEB_SOCKET_PREFIX::MESSAGE': (state, {payload}) => {
            const {client, currentScreenId} = JSON.parse(payload.message);
            if (client && currentScreenId) {
                state.currentScreenId = currentScreenId;
            }
        },
    }
});

export const {
    onToggleSpeakText: toggleSpeakText,
    onContactHelpCenter: contactHelpCenter,
    onShareScreenStart: shareScreenStart,
    setScreenSharingParams: updateScreenSharingParams,
    setRhvClient: setRhvClientValues,
    logOut: doLogOut,

} = clientSlice.actions;

export default clientSlice.reducer;

export const selectCurrentCase = () =>
    createSelector(
        [
            state => state.client.currentCaseId,
            state => state.client.clientCases.entities,
        ],
        (currentCaseId, clientCases) => {
            if (currentCaseId === undefined) {
                return undefined
            }

            return clientCases[currentCaseId] ?? undefined
        }
    );
export const getCurrentClient = (clientId) =>
    createSelector(
        [
            state => state.client.clients.entities,
            state => state.client.clients.ids,
            state => state.client.token
        ],
        (clientsEntities, clientsIds, token) => {
            if (Array.isArray(clientsIds) && clientsIds.includes(token.id)) {
                return clientsEntities[token.id];
            }
            return null
        }
    );

export const checkCurrentScreen = () =>
    createSelector([
            state => state.client.currentScreenCode,
            state => state.client.currentScreenCreatedAt,
            state => state.client.currentScreenId,
        ],
        (currentScreenCode, currentScreenCreatedAt, currentScreenId) => {
            let screen = {currentScreenCode: null, currentScreenId: null};
            if (currentScreenCreatedAt) {
                const createdAt = DateTime.fromISO(currentScreenCreatedAt);
                const now = DateTime.now();
                const diff = now.diff(createdAt, 'seconds')
                if (diff.seconds < 30 * 60) {
                    screen = {currentScreenCode, currentScreenId};
                }
            }
            return screen;
        }
    );

export const getClientCaseCount = () => createSelector([
        state => state.client.clients.entities,
    ],
    (clients) => {
        const [client] = Object.values(clients);
        return client?.clientCases?.length ?? 0;
    }
);


export const userIsRhv = () =>
    createSelector([
            state => state.client.token
        ],
        (token) => {
            return !!(token &&
                token.roles && (
                    token.roles.includes(ROLES.ADMIN) ||
                    token.roles.includes(ROLES.ASSIGNEE_ADMIN) ||
                    token.roles.includes(ROLES.ASSIGNEE_WORKER))
            );
        }
    );



