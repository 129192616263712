import axios from "axios";
import env from "@beam-australia/react-env";
import LocalStorageService from "./storage/LocalStorageService";

const API_BASE_URL = env('CLIENT_AUTH_API_BASE_URL');

const clientApi = {
    rawGet (uri) {
        return axios.get(uri, {});
    },
    get(uri, formData) {
        return axios.get(API_BASE_URL + uri, formData);
    },
    patch(uri, formData) {
        return axios.patch(API_BASE_URL + uri, formData);
    },
    post(uri, formData, headers = []) {
        try {
            const config = {
                headers: {}
            };
            headers.forEach((header) => {
                config.headers[header.name] = header.value;
            });
            return axios.post(API_BASE_URL + uri, formData, config);
        } catch (err) {
            console.log(err)
        }
    },
    delete(uri, formData) {
        return axios.delete(API_BASE_URL + uri, formData);
    }
};

// Add Bearer token
axios.interceptors.request.use(
    async config => {
        const token = LocalStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config;
    },
    error => {
        return Promise.reject(error)
    });

const refreshAccessToken = async () => {
    const result = await clientApi.post('/api/token/refresh', {
        refresh_token: LocalStorageService.getRefreshToken()
    });

    // Store accessToken (in localStorage)
    LocalStorageService.setToken(result.data);

    return result.data
};

axios.interceptors.response.use((response) => {
    if (response.data.token) {
        LocalStorageService.setToken(response.data);
    } else if(response.data.createdBy && response.data.createdBy.token){
        LocalStorageService.setToken(response.data.createdBy);
    }
    return response
}, function (error) {
    const originalRequest = error.config;

    console.log('API error: ' + error)

    // Show error to user
    if ((error.response.status === 400 || error.response.status === 500) && !originalRequest._retry) {
        console.log('Api error!')
    }
    if (error.response.status === 401 && !originalRequest._retry) {
        if (error.response.data?.message === 'Expired JWT Token') {
            originalRequest._retry = true;

            return refreshAccessToken().then(data => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;

                return axios(originalRequest);
            })
        }
    }

    return Promise.reject(error);
});

export default clientApi;
